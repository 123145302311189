import { Title } from './Tools';

const Contact = () => {
    return(
        <div className='row' id="contacts">
            <Title title="Contacts" />
            <div className="container my-4">
                <footer className=" ">
                    <div className="container p-4 pb-0">
                        <section className="">
                            <div className="row">
                                <hr className="w-100 clearfix d-md-none" />
                                <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Liens utiles</h6>
                                    <p><a href="https://form.jotform.com/242456741033553" className='linkspec' target='_blank' rel='noreferrer'>Formulaire de contact</a></p>
                                    <p><a href="https://www.instagram.com/j.exxist?igsh=MWE5dzFtNDJ6engzNw==" target='_blank' rel='noreferrer' className='linkspec'>Compte Instagram</a></p>
                                    
                                </div>
                                <hr className="w-100 clearfix d-md-none" />
                                <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Contacts</h6>
                                    <p><i className="fas fa-home mr-3"></i>Créé à Paris, France FR</p>
                                    <p><i className="fas fa-envelope mr-3"></i>Ouvert au monde entier</p> 
                                </div>
                            </div>
                        </section>
                        <hr className="my-3" />
                        <section className="p-3 pt-0">
                            <div className="row d-flex align-items-center">
                            <div className="col-md-7 col-lg-8 text-center text-md-start">
                                <div className="p-3">© 2024 Copyright: R.R.</div>
                            </div>
                            <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                            </div>
                            </div>
                        </section>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Contact; 
